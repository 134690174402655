/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Button, Link } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import { refineUrlDomain } from '@components/Utility/utils';

/* Services */
import { isVisInViewport } from '@components/Utility/Shared/SharedService';

/**
 * 
 * @param {Object} sectionContent
 * @param {String} region
 * @param {String} lang 
 * @returns 
 */
const ShivangaStickyButton = ({sectionContent, region, language}) => {
  const stickbtn = sectionContent.body[0].value.find( el => el.sectionId == 'register-sticky-button');
  const stickbtnData = stickbtn?.sectionContent;
  const [isVisible, setIsVisible] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () =>
      window.removeEventListener("scroll", listenToScroll);
  }, []);

  const listenToScroll = () => {
    const footerDiv = document.getElementById('footer');
    const heightToHideFrom = window.innerHeight * 1.4;

    const winScroll = document.body.scrollTop ||
      document.documentElement.scrollTop;    
    
    if (winScroll > heightToHideFrom) {
      const footerInViewPort = isVisInViewport(footerDiv);
      if (footerInViewPort) {
        setIsVisible(false);
      } else {
        isMobile && setIsVisible(true);
      }
    } else {
      setIsVisible(false);
    }
    
  };

  return isVisible && (
    <Box
      position="fixed"
      top={{ base: "unset", md: "30%" }}
      bottom={{ base: "2%", md: "unset" }}
      zIndex="100"
      right={{ base: "unset", md: 0 }}
      left={{ base: "50%", md: "unset" }}
      width={{ base: "217px", md: "136px" }}
      transform={{ base: "translateX(-50%)", md: "none" }}
    >
      <Button
        background="#CA4E2A"
        border="none"
        borderRadius="3px"
        fontSize="16px"
        _hover={{
            border: 'none',
            textDecor: 'none',
            bg: '#000054',
        }}
        w={{ base: 'auto', md:'243px' }}
        m="40px auto 20px"
        display="flex"
        h="54px"
        fontFamily="OpenSansRegular"
        >
        <Link
            href={refineUrlDomain(stickbtnData[0]?.linkUrl, region, language)}
            target="_blank"
            textDecor="none"
            p="18px 34px 18px 34px"
            _focus={{ outline: '0' }}
            _hover={{ textDecoration: 'none' }}
        >
            {stickbtnData[0]?.buttonText}
        </Link>
        </Button>
    </Box>
  )
}

export default ShivangaStickyButton