/* Built In Imports */

/* External Imports */
import { Box, Image, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import MediaEmbedV2 from '@components/Embeds/MediaEmbedV2';
import config from '@config';
/* Services */

/**
 * Renders the SignificanceVellagiri component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} SignificanceVellagiri component
 */

const SignificanceVellagiri = ({ sectionContent, region, language }) => {
  const signifi = sectionContent.body[0].value.find(
    el => el.sectionId == 'significance'
  );
  const signifiData = signifi?.sectionContent;
  return (
    <>
      <Box
        background="#FFEBC7"
        p={{ base: '20px 16px', md: '30px 10px 20px 10px' }}
        display="flex"
        justifyContent="center"
        alignContent="center"
      >
        <Box maxW={{ base: 'unset', sm: '870px' }} m={{ base: '0 auto' }}>
          <Box
            textAlign="center"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Text
              as="h2"
              color="#000000"
              fontFamily="MerriweatherBold"
              fontSize={{ base: '30px', sm: '36px' }}
              m={{ base: '0 0 25px 0' }}
              maxW={{ base: '325px', md: '403px' }}
            >
              {signifiData[0]?.titleText}
            </Text>
            <Image
              loading="lazy"
              alt="separate_border"
              title="separate_border"
              src={`${config.imgPath}/d/46272/1647429933-shivang_separate_border.png`}
              m={{ base: '0 0 25px 0' }}
            />
          </Box>
          <Box width={{ base: '100%', lg: '735px' }}>
            <MediaEmbedV2 sectionContent={signifiData[1]} lang={language} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SignificanceVellagiri;
