/* Built In Imports */

/* External Imports */
import { Box, Image, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */

/* Services */

/**
 * Renders the ShivangaTestimonials component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} ShivangaTestimonials component
 */

const ShivangaTestimonials = ({ sectionContent, region, language }) => {
  const testimonial = sectionContent.body[0].value.find(
    el => el.sectionId == 'testimonial'
  );
  const testimonialData = testimonial?.sectionContent;
  return (
    <>
      <Box
        background="#FFEBC7"
        p={{ base: '', sm: '5px 0 23px 0' }}
        display="flex"
        justifyContent="center"
        alignContent="center"
      >
        <Box
          display="flex"
          flexDirection={{ base: 'column', sm: 'row' }}
          justifyContent="center"
          width={{ lg: '1150px', base: '100%' }}
          gridGap="10px"
          m="0 auto 50px"
        >
          {testimonialData[0]?.testimonials?.map((el, i) => {
            return (
              <Box
                background="#FFFFFF"
                padding={{ base: '35px 20px' }}
                display="flex"
                flexDirection={{ base: 'column', lg: 'row' }}
                w={{ base: '90%', sm: '45%', lg: '50%' }}
                m={{ base: '0 auto', sm: '0 0 0 15px' }}
                fontFamily="FedraSansStd-book"
                fontSize="16px"
                alignSelf={{ base: 'center', sm: 'unset' }}
                _first={{
                  margin: '0',
                  display: { base: 'none', sm: 'inherit' },
                }}
                key={i}
              >
                <Box
                  m={{ base: '0 0 20px 0', lg: '0 27px 0 0' }}
                  w={{ base: '', lg: '64%' }}
                  minWidth={{ base: 'unset', lg: '120px' }}
                  alignSelf={{ base: 'center', lg: 'unset' }}
                >
                  <Image
                    loading="lazy"
                    src={el?.photo?.url}
                    title={el?.photo?.title}
                    alt={el?.photo?.alt}
                    borderRadius="50%"
                    width="120px"
                  />
                </Box>
                <Box color="#5A5A5A">
                  <Text
                    fontFamily="FedraSansStd-book"
                    fontSize="16px"
                    fontStyle={'italic'}
                    lineHeight={'26px'}
                  >
                    {el?.testimonialText}
                  </Text>
                  <Text
                    color="#28231E"
                    // fontFamily="MerriweatherBold"
                    fontFamily="FedraSansStd-book"
                    m={{ base: '18px 0 0 0' }}
                  >
                    <Text fontWeight={'600'} fontSize="18px">
                      {el?.userName}
                    </Text>
                    <Text as="span" color="#AF1F22" fontSize="14px">
                      {el?.userDescription}
                    </Text>
                  </Text>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default ShivangaTestimonials;
