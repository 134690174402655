/* Built In Imports */

/* External Imports */
import { Box, Image, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import StructuredTextContent from '@components/UI/StructuredTextContent';
import config from '@config';

/* Services */

/**
 * Renders the ShivangaContactus component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} ShivangaContactus component
 */

const ShivangaContactus = ({ sectionContent, region, language }) => {
  const contact = sectionContent.body[0].value.find(
    el => el.sectionId == 'Contact_us'
  );
  const contactdata = contact?.sectionContent;
  return (
    <>
      <Box background="#FFEBC7" textAlign="center" p={{ base: '50px 16px' }}>
        <Box>
          <Text
            as="h2"
            color="#000000"
            fontFamily="MerriweatherBold"
            fontSize={{ base: '30px', lg: '36px' }}
            m={{ base: '0 0 15px 0' }}
          >
            {contactdata[0]?.titleText}
          </Text>
          <Image
            loading="lazy"
            src={`${config.imgPath}/d/46272/1647429933-shivang_separate_border.png`}
            alt="separate_border"
            title="separate_border"
            m={{ base: '0 0 25px 0' }}
          />
        </Box>
        <Box
          fontFamily="OpenSansRegular"
          m="0 auto"
          fontSize={{ base: '16px', md: '18px' }}
          color="#262523"
          p={{ base: '20px 20px 30px', md: '20px 0 30px' }}
          textAlign={{ base: 'center', md: 'center' }}
          width={{ base: '100%', md: '485px' }}
        >
          {contactdata[1]?.text}
        </Box>
        <Box
          maxW={{ base: '100%', lg: '755px' }}
          m={{ base: '0 auto' }}
          background="#FFFFFF"
          color="#02093C"
          p={{ base: '10px', md: '10px' }}
          display="flex"
          minH={{ base: '106px', md: '106px' }}
          fontFamily="OpenSansRegular"
          flexDirection={{ base: 'column', sm: 'row' }}
          alignItems={{ base: 'center', md: 'center' }}
          className="shivanga-text contactdetail"
        >
          <Box
            w={{ base: '90%', md: '50%' }}
            className="contacttext"
            fontSize={{ base: '16px', md: '18px' }}
            borderRight={{ md: '0.5px solid #000000', base: 'none' }}
            borderBottom={{ base: '0.5px solid #000000', md: 'none' }}
          >
            {StructuredTextContent(contactdata[2]?.body)}
          </Box>
          <Box
            w={{ base: '80%', md: '50%' }}
            p={{ base: '10px', md: '0 70px' }}
            fontSize={{ base: '16px', md: '18px' }}
          >
            {StructuredTextContent(contactdata[3]?.body)}
          </Box>
        </Box>
      </Box>
      <style global jsx>{`
        .contacttext p {
          padding-bottom: 0 !important;
        }
      `}</style>
    </>
  );
};

export default ShivangaContactus;
