/* Built In Imports */

/* External Imports */
import { Box, Image, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import config from '@config';
/* Services */
import { nanoid } from 'nanoid';

/**
 * Renders the ShivangaBenefits component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} ShivangaBenefits component
 */

const ShivangaBenefits = ({ sectionContent, region, language }) => {
  const benefit = sectionContent.body[0].value.find(
    el => el.sectionId == 'benefits'
  );
  const benefitData = benefit?.sectionContent;
  return (
    <>
      <Box bgColor="#FFEBC7">
        <Box
          w={{ base: '90%', md: '850px' }}
          m={{ base: '0 auto', md: '0 auto' }}
          p={{ base: '55px 0 25px 0' }}
          textAlign={{ base: 'center' }}
        >
          <Box w={{ base: '90%', md: '360px' }} m="0 auto">
            <Text
              as="h2"
              color="#222222"
              fontFamily="MerriweatherBold"
              fontSize={{ base: '30px', lg: '36px' }}
              margin={{ base: '0 0 25px 0' }}
            >
              {benefitData && benefitData[0]?.titleText}
            </Text>
            <Image
              loading="lazy"
              alt="separate_border"
              title="separate_border"
              src={`${config.imgPath}/d/46272/1647429933-shivang_separate_border.png`}
              m={{ base: '0 0 55px 0' }}
            />
          </Box>
          <Box
            display={{ base: 'flex' }}
            flexDirection={{ base: 'column', sm: 'row' }}
            justifyContent={{ base: 'space-between' }}
            flexWrap={{ base: 'unset', sm: 'wrap', xl: 'unset' }}
            m="0 auto"
          >
            {benefitData &&
              benefitData[1]?.benefits.map(data => {
                return (
                  <Box
                    // w={{ base: '100%', sm: '48%', lg: '50%', xl: '25%' }}
                    p="0 10px"
                    key={nanoid}
                  >
                    <Image
                      loading="lazy"
                      alt={data?.image?.alt}
                      title={data?.image?.title}
                      src={data?.image?.url}
                      m={{ base: '0 0 22px 0' }}
                    />
                    <Text
                      as="h6"
                      color="#000000"
                      fontFamily="OpenSansRegular"
                      fontSize={{ base: '16px', md: '18px' }}
                      m={{ base: '0 auto 35px auto', lg: '0 auto 15px' }}
                      w={{ base: '80%', md: '258px' }}
                      lineHeight="26px"
                    >
                      {data?.text}
                    </Text>
                  </Box>
                );
              })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ShivangaBenefits;
