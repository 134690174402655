/* Built In Imports */

/* External Imports */
import { Box, Image, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import config from '@config';
/* Services */
import { nanoid } from 'nanoid';

/**
 * Renders the HighlightsContent component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} HighlightsContent component
 */

const HighlightsContent = ({ sectionContent, region, language }) => {
  const benefits = sectionContent.body[0].value.find(
    el => el.sectionId == 'highlights'
  );
  const benefitsData = benefits?.sectionContent;
  return (
    <>
      <Box
        backgroundSize={{ base: 'contain !important', md: 'cover !important' }}
        background={{
          base: `url('${config.staticPath}/d/46272/1701252094-shivanga_why_bg_mobile.jpg') no-repeat #101010`,
          md: `url('${config.staticPath}/d/46272/1701175411-background_7hill.jpg') no-repeat #101010`,
        }}
      >
        <Box>
          <Box
            w={{ base: '85%', sm: '50%', xl: '85%' }}
            m={{ base: '0 auto', md: '0 auto' }}
            p={{ base: '55px 0' }}
            textAlign={{ base: 'center' }}
          >
            <Text
              as="h2"
              color="#FCF6E2"
              fontFamily="MerriweatherBold"
              fontSize={{ base: '30px', lg: '36px' }}
              margin={{ base: '0 0 25px 0' }}
            >
              {benefitsData && benefitsData[0]?.title[0]?.titleText}
            </Text>
            <Image
              loading="lazy"
              alt="separate_border"
              title="separate_border"
              src={`${config.imgPath}/d/46272/1647429933-shivang_separate_border.png`}
              m={{ base: '0 0 35px 0' }}
            />
            <Box
              display={{ base: 'flex' }}
              flexDirection={{ base: 'column', sm: 'row' }}
              justifyContent="center"
              flexWrap={{ base: 'unset', sm: 'wrap', xl: 'unset' }}
              gridGap="10px"
              m="0 auto"
            >
              {benefitsData &&
                benefitsData[0]?.benefits.map(data => {
                  return (
                    <Box
                      w={{ base: '100%', sm: '48%', lg: '50%', xl: '25%' }}
                      p="0 10px"
                      key={nanoid}
                    >
                      <Image
                        loading="lazy"
                        alt={data?.image?.alt}
                        title={data?.image?.title}
                        src={data?.image?.url}
                        m={{ base: '0 0 22px 0' }}
                      />
                      <Text
                        as="h6"
                        color="#ffffff"
                        fontFamily="OpenSansRegular"
                        fontSize={{ base: '16px', md: '18px' }}
                        m={{ base: '0 auto 35px auto', lg: '0 auto 15px' }}
                        w={{ base: '80%', xl: 'auto' }}
                      >
                        {data?.text}
                      </Text>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HighlightsContent;
