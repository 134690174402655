/* Built In Imports */

/* External Imports */
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Image,
  Text,
} from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import StructuredTextContent from '@components/UI/StructuredTextContent';
import config from '@config';

/* Services */

/**
 * Renders the ShivangaFaq component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} ShivangaFaq component
 */

const ShivangaFaq = ({ sectionContent, region, language }) => {
  const faq = sectionContent.body[0].value.find(el => el.sectionId == 'FAQ');
  const faqData = sectionContent.body[0].value.find(
    el => el.sectionId == 'FaqSection'
  );
  return (
    <>
      <Box background="#02093C" textAlign="center" p={{ base: ' 0 0 40px 0' }}>
        <Text
          as="h2"
          color="#FFFFFF"
          fontFamily="MerriweatherBold"
          fontSize={{ base: '30px', lg: '36px' }}
          p={{ base: '70px 0 10px' }}
          w={{ base: '65%' }}
          m="0 auto"
        >
          {faq.sectionContent[0]?.titleText}
        </Text>
        <Image
          loading="lazy"
          src={`${config.imgPath}/d/46272/1647429933-shivang_separate_border.png`}
          alt="separate_border"
          title="separate_border"
          margin={{ base: '0 0 25px 0' }}
        />
        {faqData.sectionContent.map(data => {
          return (
            <Accordion
              allowToggle
              m={{ base: '0 auto', lg: '0 auto' }}
              w={{ base: '95%', lg: '870px' }}
            >
              <AccordionItem m="0 0 0 0" color="#FFFFFF" id="one" border="none">
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton
                        p="14px 11.8% 14px 9%"
                        border="solid 0.6px #D2D2D2"
                        borderRadius="6px"
                        id="one-btn"
                        _focus={{ outline: '0' }}
                      >
                        <Box
                          flex="1"
                          textAlign="left"
                          fontFamily="OpenSansRegular"
                          fontSize="20px"
                          lineHeight="1.2"
                        >
                          {data?.title}
                        </Box>
                        <Image
                          loading="lazy"
                          src={`${config.imgPath}/d/46272/1648228642-faq-arrow-white.svg`}
                          alt="arrow"
                          title="arrow"
                          transform={
                            isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)'
                          }
                          w="15px"
                        />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      pb={4}
                      borderTop="solid 1px rgba(255, 255, 255, 0.3)"
                      p="0"
                      textAlign="left"
                      fontFamily="OpenSansRegular"
                      fontSize="16px"
                      id="one-ap"
                      w={{ base: '94%', sm: '85%', lg: '80%' }}
                      m="0 auto"
                    >
                      {data?.faqs.map(datachild => {
                        return (
                          <Accordion
                            allowToggle
                            m={{ base: '0 auto', lg: '0 auto' }}
                            w={{ base: '95%', lg: '100%' }}
                          >
                            <AccordionItem
                              m="0 0 0 0"
                              color="#C5C5C5"
                              id="one1"
                              border="none"
                            >
                              {({ isExpanded }) => (
                                <>
                                  <h2>
                                    <AccordionButton
                                      paddingTop="20px"
                                      paddingBottom="20px"
                                      border="none"
                                      borderRadius="6px"
                                      id="one-btn1"
                                      _focus={{ outline: '0' }}
                                    >
                                      <Box
                                        flex="1"
                                        textAlign="left"
                                        fontFamily="OpenSansRegular"
                                        fontSize="18px"
                                        lineHeight="1.2"
                                        fontWeight="bold"
                                      >
                                        {datachild?.question}
                                      </Box>
                                      <Image
                                        loading="lazy"
                                        alt="Arrow"
                                        title="Arrow"
                                        src={`${config.imgPath}/d/46272/1648228642-faq-arrow-white.svg`}
                                        transform={
                                          isExpanded
                                            ? 'rotate(-180deg)'
                                            : 'rotate(0deg)'
                                        }
                                        w="15px"
                                      />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel
                                    pb={4}
                                    borderTop="none"
                                    p="5px 20px 20px"
                                    textAlign="left"
                                    fontFamily="OpenSansRegular"
                                    fontSize="16px"
                                    id="one-ap1"
                                  >
                                    <Box>
                                      <Text
                                        borderBottom="solid 1px #A7A7A7"
                                        p="0 0 30px 0"
                                      >
                                        {StructuredTextContent(
                                          datachild?.answer
                                        )}
                                      </Text>
                                    </Box>
                                  </AccordionPanel>
                                </>
                              )}
                            </AccordionItem>
                          </Accordion>
                        );
                      })}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          );
        })}
      </Box>
    </>
  );
};

export default ShivangaFaq;
