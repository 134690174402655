/* Built In Imports */
import { useState } from 'react';

/* External Imports */
import { Box, Image, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import config from '@config';
/* Services */

/**
 * Renders the SadhanaContent component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} SadhanaContent component
 */

const SadhanaContent = ({ sectionContent, region, language }) => {
  const sadhana = sectionContent.body[0].value.find(
    el => el.sectionId == 'Sadhana_details'
  );
  const sadhanaData = sadhana?.sectionContent;
  const [tabNumber, setTabNumber] = useState('1');

  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = tabNumber => {
    // setActiveTab(tabNumber);
    const anchorName = '#tab-' + tabNumber + 'c';
    window.location.href = anchorName;
  };

  const tabToggle = e => {
    const tabActive = e.target.id.replace(/\D/g, '');
    setTabNumber(tabActive);
    let matches = document?.querySelectorAll('#sadhana-details-tabs .inactive');
    matches.forEach(function (item) {
      item.classList.remove('active');
      // e.target.classList.add('active');
    });
    e.target.classList.add('active');
    const anchorName = '#' + e.target.id + 'c';
    window.location.href = anchorName;
  };

  return (
    <>
      <Box
        background="#FFEBC7"
        p={{ base: '', sm: '5px 0 63px 0' }}
        mt={{ base: '20px', md: '30px' }}
        mb={{ base: '20px', md: '30px' }}
      >
        <Box maxW={{ base: 'unset', sm: '870px' }} m={{ base: '0 auto' }}>
          <Box textAlign="center">
            <Text
              as="h2"
              color="#000000"
              fontFamily="MerriweatherBold"
              fontSize={{ base: '30px', sm: '36px' }}
              m={{ base: '0 0 25px 0' }}
            >
              {sadhanaData[0]?.titleText}
            </Text>
            <Image
              loading="lazy"
              alt="separate_border"
              title="separate_border"
              src={`${config.imgPath}/d/46272/1647429933-shivang_separate_border.png`}
              m={{ base: '0 0 25px 0' }}
            />
          </Box>
          <Box
            textAlign="center"
            fontFamily="MerriweatherBold"
            fontSize={{ base: '16px', md: '16px' }}
            m="0 auto 10px auto"
          >
            {sadhanaData[1]?.text}
          </Box>
          {/* Tabs */}
          <Box
            h={{ base: '69px' }}
            background="#FAE2AB"
            borderRadius="3px 3px 0 0"
            position={{ base: 'sticky', lg: 'sticky', md: 'sticky' }}
            top="0"
            zIndex={5}
          >
            <Box>
              <UnorderedList
                listStyleType="none"
                display="flex"
                justifyContent="space-around"
                alignItems="end"
                h={{ base: '69px' }}
                color="#000000"
                fontSize={{ base: '12px', md: '18px' }}
                fontFamily="OpenSansRegular"
                // fontWeight="bold"
                id="sadhana-details-tabs"
                m="0"
              >
                <ListItem
                  borderBottom={{ base: 'solid 6px transparent' }}
                  w={{ base: '33%' }}
                  paddingBottom={{ base: '20px' }}
                  textAlign={{ base: 'center' }}
                  // className="inactive active"
                  cursor="pointer"
                  // onClick={tabToggle}
                  onClick={() => handleTabClick(1)}
                  className={activeTab === 1 ? 'active' : ''}
                  id="tab-1"
                >
                  {sadhanaData[2]?.data[0]?.Tab_title}
                </ListItem>
                <ListItem
                  borderBottom={{ base: 'solid 6px transparent' }}
                  w={{ base: '33%' }}
                  paddingBottom={{ base: '20px' }}
                  textAlign={{ base: 'center' }}
                  // className="inactive"
                  cursor="pointer"
                  // onClick={tabToggle}
                  onClick={() => handleTabClick(2)}
                  className={activeTab === 2 ? 'active' : ''}
                  id="tab-2"
                >
                  {sadhanaData[2]?.data[1]?.Tab_title}
                </ListItem>
                <ListItem
                  borderBottom={{ base: 'solid 6px transparent' }}
                  w={{ base: '33%' }}
                  paddingBottom={{ base: '20px' }}
                  textAlign={{ base: 'center' }}
                  // className="inactive"
                  cursor="pointer"
                  // onClick={tabToggle}
                  onClick={() => handleTabClick(3)}
                  className={activeTab === 3 ? 'active' : ''}
                  id="tab-3"
                >
                  {sadhanaData[2]?.data[2]?.Tab_title}
                </ListItem>
              </UnorderedList>
            </Box>
          </Box>
          {/* Tab content */}
          <Box>
            <Box
              background={{ base: '#FFEBC7', md: '#FFF7E5' }}
              p={{ base: '13px 15px 10px', md: '13px 15px 10px 50px' }}
            >
              {/* Tab 1 */}
              <Box mb="30px">
                <Box
                  id="tab-content-1"
                  className="tab-content"
                  pb="16px"
                  borderBottom="1px solid #979797"
                  m="0 auto"
                  maxW={{ base: '90%', md: '790px' }}
                >
                  <Box
                    m={{ base: '35px 0 0 0' }}
                    display="flex"
                    flexDirection={{ base: 'column', sm: 'row' }}
                  >
                    <Box m={{ base: '0 0 40px 0', sm: 'unset' }}>
                      <LazyLoadImageComponent
                        src={sadhanaData[3]?.image?.url}
                        alt={sadhanaData[3]?.image?.alt}
                        title={sadhanaData[3]?.image?.title}
                      />
                    </Box>
                    <Box
                      display="flex"
                      margin="0 0 0 15px"
                      w={{ base: '100%', md: '328px' }}
                      borderBottom="solid 1px #E4A100"
                      alignSelf="end"
                      padding={{ base: '0 0 7px 0' }}
                    >
                      <a name="tab-1c" />
                      <Text
                        as="h4"
                        color="#000000"
                        fontSize={{ base: '20px', md: '28px' }}
                        fontFamily="MerriweatherBold"
                        fontWeight="bold"
                      >
                        <Text
                          as="span"
                          display={{ base: 'inline-block', md: 'block' }}
                          pr="10px"
                        >
                          {sadhanaData[4]?.titleText}
                        </Text>
                        <Text as="span">{sadhanaData[4]?.titleSubtext}</Text>
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    color="#000000"
                    fontSize={{ base: '16px', md: '18px' }}
                    fontFamily="OpenSansRegular"
                    m={{
                      lg: '20px 0 0 0px',
                      md: '20px 0 0 0px',
                      sm: '20px 0 0 0',
                    }}
                    className="sadhana_list tabcontent"
                  >
                    <StructuredTextParser
                      str={render(sadhanaData[5]?.body)}
                      region={region}
                      lang={language}
                    />
                  </Box>
                </Box>
                <Box
                  id="tab-content-2"
                  className="tab-content"
                  pb="16px"
                  borderBottom="1px solid #979797"
                  m="0 auto"
                  maxW={{ base: '90%', md: '790px' }}
                  sx={{
                    'ul li::marker': {
                      paddingTop: '0px',
                    },
                  }}
                >
                  <Box
                    m={{ base: '35px 0 0 0' }}
                    display="flex"
                    flexDirection={{ base: 'column', sm: 'row' }}
                  >
                    <Box m={{ base: '0 0 40px 0', sm: 'unset' }}>
                      <LazyLoadImageComponent
                        src={sadhanaData[6]?.image?.url}
                        alt={sadhanaData[6]?.image?.alt}
                        title={sadhanaData[6]?.image?.title}
                      />
                    </Box>
                    <Box
                      display="flex"
                      margin="0 0 0 15px"
                      w={{ base: '100%', md: '328px' }}
                      borderBottom="solid 1px #E4A100"
                      alignSelf="end"
                      padding={{ base: '0 0 7px 0' }}
                    >
                      <a name="tab-2c" />
                      <Text
                        as="h4"
                        color="#000000"
                        fontSize={{ base: '20px', md: '28px' }}
                        fontFamily="MerriweatherBold"
                        fontWeight="bold"
                      >
                        <Text
                          as="span"
                          display={{ base: 'inline-block', md: 'block' }}
                          pr="10px"
                        >
                          {sadhanaData[7]?.titleText}
                        </Text>
                        <Text as="span">{sadhanaData[7]?.titleSubtext}</Text>
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    color="#000000"
                    fontSize={{ base: '16px', md: '18px' }}
                    fontFamily="OpenSansRegular"
                    m={{
                      md: '20px 0 0 30px',
                      base: '20px 0 0 16px',
                    }}
                    className="sadhana_list tabcontent moredetail moredetail"
                  >
                    <StructuredTextParser str={render(sadhanaData[8]?.body)} />
                    <StructuredTextParser
                      str={render(
                        sadhanaData[8].body.value.document.children[2]
                      )}
                    />
                  </Box>
                </Box>
                <Box
                  id="tab-content-3"
                  className="tab-content"
                  m="0 auto"
                  maxW={{ base: '90%', md: '790px' }}
                >
                  <Box
                    m={{ base: '35px 0 0 0' }}
                    display="flex"
                    flexDirection={{ base: 'column', sm: 'row' }}
                  >
                    <Box m={{ base: '0 0 40px 0', sm: 'unset' }}>
                      <LazyLoadImageComponent
                        src={sadhanaData[9]?.image?.url}
                        alt={sadhanaData[9]?.image?.alt}
                        title={sadhanaData[9]?.image?.title}
                      />
                    </Box>
                    <Box
                      display="flex"
                      margin="0 0 0 15px"
                      w={{ base: '100%', md: '328px' }}
                      borderBottom="solid 1px #E4A100"
                      alignSelf="end"
                      padding={{ base: '0 0 7px 0' }}
                    >
                      <a name="tab-3c" />
                      <Text
                        as="h4"
                        color="#000000"
                        fontSize={{ base: '20px', md: '28px' }}
                        fontFamily="MerriweatherBold"
                        fontWeight="bold"
                      >
                        <Text
                          as="span"
                          display={{ base: 'inline-block', md: 'block' }}
                          pr="10px"
                        >
                          {sadhanaData[10]?.titleText}
                        </Text>
                        <Text as="span">{sadhanaData[10]?.titleSubtext}</Text>
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    color="#000000"
                    fontSize={{ base: '16px', md: '18px' }}
                    fontFamily="OpenSansRegular"
                    m={{
                      lg: '20px 0 0 0px',
                      md: '20px 0 0 0px',
                      sm: '20px 0 0 0',
                    }}
                    className="sadhana_list tabcontent"
                  >
                    <StructuredTextParser
                      str={render(sadhanaData[11]?.body)}
                      region={region}
                      lang={language}
                      className="bullettext"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <style global jsx>{`
          .tabcontent p {
            font-size: 18px;
          }
          .bullettext {
            margin-left: 0px !imporatnt;
          }
          .tab-content li p {
            padding: 0 !important;
          }
          #tab-content-2 ul,
          #tab-content-2 ul .chakra-collapse,
          #tab-content-2 ul li {
            margin: 0;
            padding: 0;
          }
          #tab-content-2 .css-19hzi20 {
            margin-top: 0;
            list-style: none;
          }
          #tab-content-2 .css-19hzi20 li::before {
            // content: '•';
            position: absolute;
          }
          #tab-content-2 .css-19hzi20 .chakra-collapse {
            overflow: inherit !important;
          }
          #tab-content-2 .css-19hzi20 li {
            position: relative;
            display: flex;
            margin-left: 0px;
            list-style-type: disc;
          }
          #tab-content-2 .css-19hzi20 li p:before {
            content: '';
            position: absolute;
            top: 10px;
            left: 0px;
            display: list-item;
            padding-left: -20px !important;
          }
          .moredetail a {
            padding: 0px;
            text-align: left !important;
          }
          .moredetail .css-vpkyow {
            text-align: left;
            position: unset;
          }
          .moredetail .css-vpkyow a {
            border: none !important;
            color: #ca4e2a;
            font-family: FedraSansStd-book !important;
            text-decoration: none !important;
            margin-bottom: 5px;
          }
          .moredetail .css-vpkyow a:hover {
            background: none;
            color: #ca4e2a !important;
            text-dection: underline;
          }
          #tab-content-1 p {
            text-align: center;
            padding: 15px 0;
          }
          #tab-content-1 ul p {
            text-align: left;
          }
          #sadhana-details-tabs .active {
            font-weight: bold;
          }
          @media screen and (max-width: 768px) {
            #tab-content-1 p {
              text-align: left;
            }
            .tabcontent p {
              font-size: 16px;
            }
          }
        `}</style>
      </Box>
    </>
  );
};

export default SadhanaContent;
