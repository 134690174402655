/* Built In Imports */

/* External Imports */
import { Box, Image, Link, Text } from '@chakra-ui/react';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/* Internal Imports */
/* Components */
import config from '@config';

/* Services */

/**
 * Renders the Fireup component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} Fireup component
 */

const Fireup = ({ sectionContent, region, language }) => {
  const fireup = sectionContent?.body?.[0]?.value?.find(
    el => el?.sectionId == 'Fire_up'
  );
  const fireupdata = fireup?.sectionContent;
  return (
    <>
      <Box background="#FFEBC7">
        <Box
          w={{ base: 'auto', sm: 'auto', lg: '870px' }}
          m={{ base: '0 auto' }}
          textAlign="center"
        >
          <Box>
            <Text
              as="h2"
              color="#000000"
              fontFamily="MerriweatherBold"
              fontSize={{ base: '30px', lg: '36px' }}
              m={{ base: '20px auto 10px' }}
              w={{ base: '80%', lg: '65%', md: '65%' }}
              lineHeight="50px"
            >
              {fireupdata?.[0]?.titleText}
            </Text>
            <Image
              loading="lazy"
              src={`${config.imgPath}/d/46272/1647429933-shivang_separate_border.png`}
              alt="separate_border"
              title="separate_border"
              m={{ base: '0 0 25px 0' }}
            />
            <Box
              fontFamily="OpenSansRegular"
              width={{ base: '100%', md: '693px' }}
              m="0 auto"
              fontSize={{ base: '16px', md: '18px' }}
              color="#000000"
              lineHeight={{ base: '24px', md: '28px' }}
              textAlign={{ base: 'left', md: 'center' }}
              p={{ base: '0 20px', md: '0' }}
            >
              <Text>{fireupdata?.[1]?.text}</Text>
            </Box>
          </Box>
          <Box
            display={{ base: 'flex' }}
            m={{ base: '50px auto 40px auto' }}
            color="#000000"
            fontFamily="MerriweatherBold"
            fontSize={{ base: '12px', sm: '16px' }}
            flexDirection={{ base: 'column', sm: 'unset' }}
            // className="shivanga-spruthi"
            w={{ base: '90%' }}
            justifyContent="center"
            alignItems="center"
          >
            {fireupdata?.[2]?.simpleCards?.map((data, i) => {
              return (
                <Box
                  w={{ base: '100%', sm: '33%' }}
                  borderRight={{ base: 'unset', sm: 'solid 1px #BB9B4B' }}
                  borderBottom={{ base: 'solid 1px #BB9B4B', sm: 'unset' }}
                  p={{ base: '10px', sm: '10px 0' }}
                  _last={{ borderRight: 'none', borderBottom: 'none' }}
                  display={{ base: 'flex', md: 'flex' }}
                  alignItems="center"
                  key={i}
                  justifyItems={'center'}
                  flexFlow={{ base: 'row', md: 'column' }}
                >
                  {i == 2 ? (
                    <>
                      <Link
                        href={fireupdata?.[2]?.title[0]?.titleSubtext}
                        target="_blank"
                        rel="noreferrer"
                        display={{ base: 'flex', md: 'block' }}
                        justify-content={{ base: 'center' }}
                        align-items={{ base: 'center' }}
                      >
                        <LazyLoadImageComponent
                          src={data?.rectangularImage?.url}
                          alt={data?.rectangularImage?.alt}
                          title={data?.rectangularImage?.title}
                          w={{ base: '22px', md: 'auto' }}
                          h={{ base: '22px', md: 'auto' }}
                          mt={{ base: '10px' }}
                        />
                        <Text
                          fontFamily="OpenSansRegular"
                          margin={{
                            base: '8px 15px 5px 10px',
                          }}
                          fontSize={{
                            base: '16px !important',
                            md: '18px !important',
                          }}
                          color="#000000"
                          minw={{ base: '90px', md: '170px' }}
                        >
                          {data?.title}
                        </Text>
                        <Text
                          textAlign={{ base: 'left', md: 'center' }}
                          w={{ base: 'auto', md: '170px' }}
                          fontSize={{ base: '16px', md: '18px' }}
                          lineHeight={{ base: '24px', md: '28px' }}
                          fontWeight="bold"
                          pt={{ base: '9px', md: '0px' }}
                        >
                          {data?.descriptionText}
                        </Text>
                      </Link>
                    </>
                  ) : (
                    <>
                      <LazyLoadImageComponent
                        src={data?.rectangularImage?.url}
                        alt={data?.rectangularImage?.alt}
                        title={data?.rectangularImage?.title}
                        w={{ base: '22px', md: 'auto' }}
                        h={{ base: '22px', md: 'auto' }}
                      />
                      <Text
                        fontSize={{ base: '16px', md: '18px' }}
                        fontFamily="OpenSansRegular"
                        margin={{ base: '8px 25px 5px 10px', sm: '10px 0 5px' }}
                        color="#000000"
                      >
                        {data?.title}
                      </Text>
                      <Text
                        textAlign={{ base: 'left', md: 'center' }}
                        fontSize={{ base: '16px', md: '18px' }}
                        lineHeight={{ base: '24px', md: '28px' }}
                        fontWeight="bold"
                      >
                        {data?.descriptionText}
                      </Text>
                    </>
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Fireup;
