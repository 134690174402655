/* Built In Imports */

/* External Imports */
import { Box, Button, Image, Link, Text } from '@chakra-ui/react';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/* Internal Imports */
/* Components */
import StructuredTextContent from '@components/UI/StructuredTextContent';
import { refineUrlDomain } from '@components/Utility/utils';
/* Services */

/**
 * Renders the ShivangaBnner component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} ShivangaBnner component
 */

const ShivangaBnner = ({ sectionContent, region, language, userRegion }) => {
  const banner = sectionContent.body[0].value.find(
    el => el.sectionId == 'HeaderBanner'
  );
  const bannerData = banner?.sectionContent;
  const regUrl =
    refineUrlDomain(bannerData[4]?.linkUrl, region, language) + userRegion;
  return (
    <>
      <Box>
        <Box
          background={{
            base: `url('${bannerData[0]?.mobileImage?.url}') no-repeat #000000 center`,
            lg: `url('${bannerData[0]?.image?.url}') no-repeat #000000 center`,
            md: `url('${bannerData[0]?.image?.url}') no-repeat #000000 center`,
          }}
          w={{ base: '100%' }}
          position="relative"
          textAlign="center"
          minHeight={{ base: '366px', sm: '701px' }}
          backgroundSize="cover"
          backgroundPosition="center !important"
          backgroundColor="#000000"
        >
          <Box
            w={{ base: 'auto', xl: '1360px' }}
            m={{ base: '0 auto' }}
            p={{ lg: '35px 45px 0 45px' }}
          >
            <Box
              display={{ base: 'inherit', lg: 'inherit' }}
              textAlign="left"
              padding={{ base: '20px', md: '0' }}
            >
              <Image
                loading="lazy"
                src={bannerData[1]?.image?.url}
                title={bannerData[1]?.image?.title}
                alt={bannerData[1]?.image?.alt}
                width={{ base: '50%', md: 'auto' }}
              />
            </Box>
            <Box width={{ base: '90%', md: '497px' }} m="0 auto">
              <Text
                as="h1"
                fontFamily="MerriweatherBold"
                color="#FFA735"
                fontSize={{ lg: '52px', md: '40px', base: '28px' }}
                fontWeight="700"
                pt={{ base: '60px', md: '80px', lg: '100px' }}
              >
                {bannerData[2]?.titleText}
              </Text>
              <Text
                as="h3"
                fontFamily="MerriweatherBold"
                color="#FFFFFF"
                fontSize={{ lg: '33px', md: '28px', base: '20px' }}
                fontWeight="700"
              >
                {bannerData[2]?.titleSubtext}
              </Text>
              <Text
                fontFamily="FedraSansStd-book"
                color="#F5E3B5"
                fontSize="22px"
              >
                {bannerData[3]?.text}
              </Text>
            </Box>
            <Button
              background="#CA4E2A"
              border="none"
              borderRadius="3px"
              fontSize="16px"
              _hover={{
                border: 'none',
                textDecor: 'none',
                bg: '#000054',
              }}
              w={{ base: 'auto', md: '243px' }}
              m="40px auto 20px"
              display="flex"
              h="54px"
              fontFamily="OpenSansRegular"
              position={{ base: 'absolute', md: 'inherit' }}
              top={{ base: '340px' }}
              left={{ base: '22%' }}
            >
              <Link
                href={regUrl}
                target="_blank"
                textDecor="none"
                p="18px 34px 18px 34px"
                _focus={{ outline: '0' }}
                _hover={{ textDecoration: 'none' }}
              >
                {bannerData[4]?.buttonText}
              </Link>
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        display={{ base: 'block', md: 'none' }}
        height={'78px'}
        bgColor={'#171001'}
      ></Box>
      <Box
        background="#000000"
        p={{ lg: '20px 15px', base: '20px 15px 40px 15px' }}
        minH={{ base: '240px' }}
      >
        <Box
          maxW={{ base: '328px', md: '475px' }}
          margin="0 auto"
          textAlign="center"
        >
          <Text
            color="#C1C1C1"
            fontSize={{ base: '16px', md: '18px' }}
            textAlign={{ base: 'center', sm: 'center' }}
            margin="20px 0 20px 0"
            lineHeight="28px"
            className="bannerparatext"
            fontFamily="OpenSansRegular"
            minH={{ base: '70px', md: '70px' }}
          >
            {StructuredTextContent(bannerData[5]?.body)}
          </Text>
          <LazyLoadImageComponent
            src={bannerData[6]?.image?.url}
            title={bannerData[6]?.image?.title}
            alt={bannerData[6]?.image?.alt}
          />
        </Box>
      </Box>
    </>
  );
};

export default ShivangaBnner;
