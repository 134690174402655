/* Built In Imports */

/* External Imports */
import { Box, Image, Text } from '@chakra-ui/react';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/* Internal Imports */
/* Components */
import SingleLineText from '@components/UI/SingleLineText';
import StructuredTextContent from '@components/UI/StructuredTextContent';
/* Services */

/**
 * Renders the WhatisShivanga component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} WhatisShivanga component
 */

const WhatisShivanga = ({ sectionContent, region, language }) => {
  const whatis = sectionContent.body[0].value.find(
    el => el.sectionId == 'Section_1'
  );
  const whatisData = whatis?.sectionContent;
  return (
    <>
      <Box
        background="#00052F"
        display={{ base: 'flex' }}
        flexDirection={{ base: 'column-reverse', sm: 'row' }}
        // w={{ base: 'auto', sm: '90%', xl: '1300px' }}
        m={{ base: 'unset', sm: '0 auto' }}
        p={{ base: '0 0 25px 0', sm: 'unset' }}
        className="whatisshivanga"
        justifyContent="center"
      >
        <Box
          w={{ base: '', sm: '50%', lg: '365px' }}
          margin={{
            base: '0 16px 0',
            sm: '40px 10% 0 0',
            lg: '40px 10% 0 2.5%',
          }}
        >
          <Text
            as="h2"
            color="#FFA735"
            fontSize={{ base: '30px', lg: '36px' }}
            fontFamily="MerriweatherBold"
            lineHeight="44px"
            m="0 0 25px 0"
          >
            {whatisData[0]?.titleText}
          </Text>
          <Text
            color="#FFFFFF"
            fontSize={{ base: '20px', lg: '22px' }}
            fontFamily="OpenSansRegular"
            lineHeight={{ base: '34px', md: '34px' }}
          >
            {StructuredTextContent(whatisData[2]?.body)}
          </Text>
        </Box>
        <Box
          m={{ base: '40px 16px 39px', sm: '48px 0 43px 0' }}
          w={{ base: 'unset', sm: '40%', lg: 'unset' }}
        >
          <Image
            loading="lazy"
            src={whatisData[1]?.image?.url}
            title={whatisData[1]?.image?.title}
            alt={whatisData[1]?.image?.alt}
          />
        </Box>
      </Box>
      <Box background="#FFEBC7" p={{ base: '0 10px', sm: '40px 0 5px 0' }}>
        <Box w={{ base: 'auto', lg: '710px' }} m={{ base: '', lg: '0 auto' }}>
          <Box display="flex" alignItems="start" pt="10px">
            <Box
              width="50px"
              display="block"
              top={{ base: '-6px', md: '2px', xl: '5px' }}
            >
              <LazyLoadImageComponent
                src="https://static.sadhguru.org/d/46272/1700928393-icon_calendardays.svg"
                pt="3px"
              />
            </Box>
            <SingleLineText
              sectionContent={whatisData[3]}
              fontFamily="OpenSansRegular"
              my="0px"
              className="shivanga-text"
            />
          </Box>
          <Box display="flex" alignItems="start" mt="10px">
            <Box
              width="50px"
              display="block"
              top={{ base: '-17px', md: '-5px', xl: '-4px' }}
            >
              <LazyLoadImageComponent
                src="https://static.sadhguru.org/d/46272/1700928391-icon_calendarclock.svg"
                pt="3px"
              />
            </Box>
            <SingleLineText
              sectionContent={whatisData[4]}
              fontFamily="OpenSansRegular"
              my="0px"
              className="shivanga-text"
            />
          </Box>
          <Box alignItems="start" mb="0px">
            <Box as="section" ml="45px" className="shivanga-text whatisdata">
              {StructuredTextContent(whatisData[5]?.body)}
            </Box>
          </Box>
          {whatisData[6] != undefined && (
            <Box display="flex" alignItems="start" mt="10px" pb="10px">
              <Box
                width="45px"
                display="block"
                top={{ base: '-17px', md: '5px' }}
              >
                <LazyLoadImageComponent
                  src="https://static.sadhguru.org/d/46272/1700928388-icon_translate.svg"
                  pt="3px"
                />
              </Box>
              <Box
                ml={{ base: '20px', md: '0px !important' }}
                as="section"
                className="shivanga-text whatisdata"
              >
                {StructuredTextContent(whatisData[6]?.body)}
              </Box>
            </Box>
          )}
        </Box>
        <style global jsx>{`
          .whatisdata p {
            text-align: left;
          }
          .whatisdata a {
            text-decoration: none;
            color: #ca4e2a;
            font-weight: bold;
          }
          .whatisdata p mark {
            color: #ca4e2a;
            background: transparent;
          }
          .shivanga-text p {
            padding: 0 0 10px 0;
            font-family: 'OpenSansRegular' !important;
          }
          .shivanga-text a:hover span {
            background-color: #cf4824;
            color: white;
            background-image: linear-gradient(120deg, #cf4824, #cf4824);
            background-size: 100% 100%;
            transition: all 0.3s ease-in-out;
          }
          .shivanga-text a:hover span {
            color: white;
            text-decoration: underline;
          }
        `}</style>
      </Box>
    </>
  );
};

export default WhatisShivanga;
