/* Built In Imports */
import React from 'react';

/* External Imports */
import { Box, Image, Link, Stack, Button, Text } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import StructuredTextContent from '@components/UI/StructuredTextContent';
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';
/* Services */

/**
 * Renders the ShivangaRegistration component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} ShivangaRegistration component
 */

const ShivangaRegistration = ({ sectionContent, region, language, userRegion }) => {
    const register = sectionContent.body[0].value.find( el => el.sectionId == 'registration');
    const registerData = register?.sectionContent;
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const regUrl = refineUrlDomain(registerData[4]?.linkUrl, region, language) + userRegion;
    return (
      <>
        {registerData[0]?.titleText &&
          <Box p="60px 0" w={{base: '100%', md: '100%'}}  background="#02093C">
          <Box spacing={10} align="center" justify="center">
          <Box>
          <Text
              as="h2"
              color="#FCF6E2"
              fontFamily="MerriweatherRegular"
              fontSize={{ base: '30px', lg: '36px' }}
              margin={{ base: '0 0 25px 0' }}
            >
              {registerData[0]?.titleText}
            </Text>
            <Image
              loading="lazy"
              alt="separate_border"
              title="separate_border"
              src={`${config.imgPath}/d/46272/1647429933-shivang_separate_border.png`}
              m={{ base: '0 0 55px 0' }}
            />
          </Box>
            <Box m={{base:'0 16px', md:'20px 0'}} p={{base:'10px 0px', md:'50px 0'}} w={{ base: '92%', md: '707px' }}  background="#FFFFFF" minH={{base:'297px', md:'357px'}} >
              <Stack spacing={2} align="center">
                <Text mt={{base:'20px', md:'30px'}} w={{base:'170px', md:'100%'}} fontFamily="OpenSansRegular" textAlign="center" fontWeight={600} fontSize={{ base: '16px', sm: '18px' }}>{registerData[1]?.text}</Text>
                <Text fontFamily="OpenSansRegular" pb="10px" textAlign="center" color="#CA4E2A" fontWeight={600} fontSize={{ base: '32px', sm: '48px' }}>{registerData[2]?.text}</Text>
                <Box fontFamily="OpenSansRegular" textAlign="center" maxW={{base:'170px', md:'430px'}} className='regn-txt event-text' fontWeight={400} fontSize={{ base: '16px', sm: '18px' }} lineHeight={{base:'20px', md:'30px'}}>{StructuredTextContent(registerData[3]?.body)}</Box>
              </Stack>
            </Box>
            {!isMobile && (
            <Button
                background="#CA4E2A"
                border="none"
                borderRadius="3px"
                fontSize="16px"
                _hover={{
                    border: 'none',
                    textDecor: 'none',
                    bg: '#000054',
                }}
                w={{ base: 'auto', md:'243px' }}
                m="40px auto 20px"
                display="flex"
                h="54px"
                fontFamily="OpenSansRegular"
                >
                <Link
                    href={regUrl}
                    target="_blank"
                    textDecor="none"
                    p="18px 34px 18px 34px"
                    _focus={{ outline: '0' }}
                    _hover={{ textDecoration: 'none' }}
                >
                    {registerData[4]?.buttonText}
                </Link>
            </Button>
            )}
          </Box>
          <style global jsx>{`
            .regn-txt p {
              font-family: OpenSansRegular !important;
            }
            .regn-txt p:nth-child(2) {
                margin-top: 20px;
            }
          `}</style>
        </Box>}
      </>
    );
};

export default ShivangaRegistration;
